@import './src/designSystem/colors.scss';

.edit-zones-wrapper{
    max-width: 600px;

    .image-picker{
        margin-left: 20px;
        max-width:100px;
        
        .image-picker-wrapper{
            padding: 0;
        }
    }

    h6{
        color: $font-primary;
        margin-bottom: 25px;
        font-weight: 500;
        color: #fff;
        font-size: 14px;
    }
    
    .reservation-wrapper{
        margin-top: 25px;
        display: grid;
        grid-template-columns: 40% 1fr 1fr;
        grid-gap: 20px;

        position: relative;
        
        .icons-reservation-wrapper{
            position: absolute;
            top: 0;
            left: 600px;
            height: 100%;

            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 24px;
            color: $font-primary;

            margin-top: 15px;

            .icon-wrapper {
                display: flex;
                justify-content: center;
                padding: 5px;

                svg{
                    cursor: pointer
                }
            }
        }

    }


    button{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .edit-zones-wrapper{
        margin: 0 20px;
    }
}
