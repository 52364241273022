@import "designSystem/colors.scss";

.editProfile-title-wrapper{
    display: flex;
    h3:first-child{
        padding-right: 8px;
    }
    svg:last-child{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.edit-profile-wrapper {
    width: 100%;
    height: 100%;

    .inputs-wrapper {
        width: 40%;
        min-width: 650px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        padding-left: 30px;

        .row {
            display: flex;
            align-items: center;
            width: 100%;

            div:first-child {
                margin-right: 20px;
            }
        }

        .input-wrapper {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .editProfile-title-wrapper{
        flex-wrap: wrap;
    }

    .edit-profile-wrapper {
        .inputs-wrapper {
            width: 100%;
            min-width: 100%;
            box-sizing: border-box;

            padding-bottom: 30px;

            .row {
                flex-wrap: wrap;

                div:first-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
