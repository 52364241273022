@import "./src/designSystem/colors.scss";

.register-component {
    display: flex;
    flex-direction: column;
    width: 100%;

    h2{
        font-size: 22px;
        color: #FFF;
        margin-top: 0;
        font-weight: 500;
    }
    
    p{
        color: $font-subtitle;
        margin-top: 0;
        font-weight: 500;
    }

    form {
        width: 100%;

        div.input-wrapper {
            margin-bottom: 20px;
        }

        button:first-of-type {
            margin-top: 20px;
        }
    }
}
