@import 'designSystem/colors.scss';

.details-button-wrapper {
    padding: 5px 10px;
    border: none;
    color: #fff;

    font-weight: 400;

    background: linear-gradient(135deg, #fe5833 0%, #fe7455 100%);
    border-radius: 6px;

    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
