@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins-Bold.ttf");
    font-weight: 700;
}

body,
* {
    font-family: "Poppins";
}

html,
body,
#root,
.App,
.App > div {
    margin: 0;
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}
