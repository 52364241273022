@import "designSystem/colors.scss";

.club-admin-wrapper{
    flex-grow: 1;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding-right: 30px;

    .loading-wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .action-buttons-wrapper {
        display: flex;
        align-items: center;

        color: #fff;

        div {
            cursor: pointer;
            font-size: 20px;
            margin-right: 16px;
        }

        div:last-child {
            margin-right: 0;
        }
    }
}

.admin-modal-wrapper{
    background-color: $background;
}

.button-wrapper{
    background-color: $background;
}

@media only screen and (max-width: 767px) {
    .club-admin-wrapper{
        padding: 0 30px;
    }
}