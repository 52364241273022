@import 'designSystem/colors.scss';

.publications-wrapper {
    padding-right: 30px;

    .modal-components {
        width: 560px;

        padding: 15px 30px;
    }

    .modal-button-wrapper {
        padding: 0 30px;
        padding-bottom: 30px;
    }

    .action-buttons-wrapper {
        display: flex;
        align-items: center;
        color: #fff;

        div {
            display: flex;
            margin-right: 16px;
            cursor: pointer;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .publications-wrapper {
        padding: 0 30px;

        .modal-components {
            width: 100%;

            padding: 15px 0;
        }
    }
}
