@import './src/designSystem/colors.scss';

.promotions-wrapper {
    padding-right: 30px;
    height: calc(100% - 80px);

    .noData-wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $accent;
        font-weight: 500;

        img {
            width: 50px;
            height: 44px;
            margin-bottom: 20px;
        }
    }

    .table-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .action-buttons-wrapper {
        display: flex;
        align-items: center;
        color: #fff;

        div {
            display: flex;
            margin-right: 16px;
            cursor: pointer;
            font-size: 20px;
        }
    }

    .modal-components {
        width: 600px;
        padding: 10px 30px;

        &__dates {
            max-width: 600px;
            width: 100%;
            padding: 10px 30px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        &__image {
            position: relative;
            .image-picker-wrapper {
                padding: 0 30px;
                .image-component-wrapper {
                    max-width: 600px;

                    img {
                    }
                }
            }

            .close-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 10px;
                right: 40px;

                color: $font-primary;

                background-color: #000;
                border-radius: 100px;
            }
        }
    }
}
