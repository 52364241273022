@import "designSystem/colors.scss";

.button-component {
    padding: 12px 25px;
    color: $font-primary;
    border: none;
    text-decoration: none;
    border-radius: 45px;
    cursor: pointer;
    width: 100%;
    font-size: 15px;
    margin-bottom: 10px;
    font-family: "Montserrat";
    font-weight: 500;
    background: linear-gradient(135deg, #FE5833 0%, #FE7455 100%);

    .button-component-loading {
        flex: 1;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-content {
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            margin-right: 5px;
        }
    }

    &.secondary {
        border: 1px solid $primary;
        color: $primary;
        background-color: $font-primary;
    }

    &.cancel {
        background-color: $font-subtitle;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: 0.9;
    }

    &.disabled {
        // opacity: 0.5;
        cursor: default;
        background: $background;
        color: $accent;
        border: 1px solid $accent;
    }

    &.round {
        border-radius: 32px;
    }

    &.light {
        background-color: unset;
        color: $font-primary;
    }

    &.small {
        padding: 7px 15px;
    }

    &.no-actions {
        pointer-events: none;
    }

    &.danger {
        background-color: $error;
    }
}
