@import "./src/designSystem/colors.scss";

.login-component {
    display: flex;
    flex-direction: column;
    width: 100%;

    form {
        width: 100%;

        div.input-wrapper:first-of-type {
            margin-bottom: 20px;
        }

        .forgot-password {
            display: block;
            text-decoration: underline;
            color: $font-primary;
            width: 100%;
            margin-bottom: 30px;
            margin-top: 10px;
            text-align: right;
            font-size: 14px;
        }
    }
}
