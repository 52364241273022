@import './src/designSystem/colors.scss';

.zones-wrapper{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    padding-right: 30px;

    .zones-content-wrapper{
        flex-grow: 1;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-template-rows: repeat(auto-fit, 220px);
        grid-gap: 25px;

        .zone-component{
            width: 100%;
            height: 220px;

            background-color: $light-background;
            border-radius: 6px;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 24px;
            color: $font-primary;

            background-size: cover;
            
            h5{
                padding: 10px;
                color: $font-primary;
                font-size: 24px;
                text-align: center;
                text-shadow: 2px 2px 5px #000;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #000;
                margin: 0;
            }

            .color-opacity{
                width: 100%;
                height: 100%;
                display: none;
                border-radius: 6px;
                background-color: #00000090;

                .icons-zone-wrapper{
                    display: none;
                    width: 80px;
                    // display: flex;
                    justify-content: space-between;
                    align-items: center;
                    svg{
                        cursor: pointer;
                    }
                }
            }



            &:hover{
                h5{
                    display: none;
                }
                .color-opacity{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icons-zone-wrapper{
                        display: flex;
                        
                    }
                }
            }
        }
    }
}

.modal-zone-wrapper{
    width: 500px;
    .input-name-wrapper{
        padding: 0 30px;
        padding-top: 10px;
    }
}
.zones-image-map-wrapper{
    padding: 0 30px;
    width: 461px;
    height: 380px;
}


@media only screen and (max-width: 767px) {
    .zones-wrapper{
        padding: 0 20px;
    }
}
