// Main Palette
$primary: #FE5833;
$accent: #7C85A5;
$background: #1E2630;
$light-background: #252C34;

// Texts Palette
$font-primary: #fff;
$font-title: #fff;
$font-subtitle: #828282;

// Other
$border-color: #a9a9a9;
$error: #f44336