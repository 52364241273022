@import "./src/designSystem/colors.scss";

.ckeckIn-wrapper {
    background-color: $background;
    color: $font-primary;
    height: 100%;

    .icon-wrapper {
        color: #6fcf97;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 70px;
        margin-bottom: 8px;

        padding: 30px 15px 0 15px;
    }

    .message-wrapper {
        text-align: center;
        font-size: 14px;
        h2 {
            font-size: 20px;
            color: $font-primary;
            margin: 0;
        }

        padding: 0 15px;
    }

    .selected-user-wrapper {
        background-color: $light-background;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 20px 15px;
        padding: 14px;
        border-radius: 12px;

        .row-align {
            display: flex;
            align-items: center;

            .user-image-wrapper {
                margin: 0;
                img {
                    width: 30px;
                    height: 30px;

                    border-radius: 50%;
                }
            }

            h5 {
                color: $font-primary;
                margin: 0;
                margin-left: 8px;
                grid-area: name;

                display: flex;
                align-items: center;
            }

            .pagado-icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;

                margin-right: 10px;
            }
        }
    }

    .entrance-wrapper {
        padding: 0 15px;
        h4 {
            color: $font-primary;
        }

        hr {
            color: #fff;
            opacity: 0.12;
        }

        .entrance-details-wrapper {
            display: grid;
            grid-template-columns: 20px 1fr;
            grid-template-areas: "icon infos";

            .entrance-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                color: #fe5833;
            }

            h5 {
                color: $font-primary;
                margin: 0;
                margin-left: 8px;
                grid-area: infos;
            }
        }

        .entrance-info-details {
            grid-area: infos;
            margin-bottom: 16px;
        }
    }

    .users-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        max-height: 260px;
        overflow-y: auto;

        padding: 0 15px;

        div {
            margin-bottom: 16px;
        }

        div:last-child {
            margin-bottom: 0;
        }

        .user-status-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: 30px 1fr 50px 80px 40px;
            grid-template-areas: "image name price status icon";

            .user-image-wrapper {
                margin: 0;
                img {
                    width: 30px;
                    height: 30px;

                    border-radius: 50%;
                }
            }

            h5 {
                color: $font-primary;
                margin: 0;
                margin-left: 8px;
                grid-area: name;

                display: flex;
                align-items: center;
            }

            .price-status-wrapper {
                font-size: 13px;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                margin: 0;
            }

            .pagado-icon-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                color: #6fcf97;
                font-size: 24px;
            }
        }
    }

    .loading-spin-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        .spin-wrapper {
            border: 8px solid $light-background;
            border-top: 8px solid $primary;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .button-wrapper {
        margin-top: 20px;
        padding: 15px;

        .back-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        .success{
            text-align: center;
            color:#6fcf97;
            font-size: 16px;
        }
        .error{
            text-align: center;
            color:rgb(233, 70, 70);
            font-size: 16px;
        }
    }

    .base-icon-wrapper {
        position: absolute;
        bottom: 20px;

        width: 100%;
        display: flex;
        justify-content: center;

        img {
            width: 50px;
            height: 55px;
        }
    }
}
