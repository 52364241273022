@import './src/designSystem/colors.scss';

.modal-wrapper{

    .modal-components {
        width: 560px;

        padding: 0 30px;
    }
}


@media only screen and (max-width: 767px) {
    .modal-wrapper{

        .modal-components {
            width: 100%;

            padding: 0 30px;
        }
    }
}
