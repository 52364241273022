@import './src/designSystem/colors.scss';

.check-box-wrapper{
	margin-top: 5px;
	
	.MuiCheckbox-root{
		color: $font-primary;
		
		&.Mui-checked{
			color: $primary;
		}
	}
}