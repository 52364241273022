@import "./src/designSystem/colors.scss";

.stage-details-wrapper{
    padding-right: 30px;
    height: 100%;

    .request-fail-wrapper{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        height: 100%;
    }

    .total-payment-wrapper{
        padding-top: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        color: $accent;
        display: block;
        text-align: end;
    }
    
    h3{
        color: $font-primary;
        text-transform: uppercase;
        margin: 10px 0;
    }
}