@import "designSystem/colors.scss";

.available-button-wrapper{
    width: 120px;
    

    cursor: default;

    font-size: 13px;

    .available{
        height: 100%;
        background-color: #6FCF9718;
        color: #A5FFCD;
        padding: 6px 10px;
        border-radius: 6px;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .unavailable{
        height: 100%;
        background-color: #EB575718;
        color: #FF7979;
        padding: 6px 10px;
        border-radius: 6px;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .available-button-wrapper{
    }    
}
