@import "designSystem/colors.scss";

.title-wrapper{
    width: 100%;
    min-width: 300px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: 15px 0px;

    h2{
        font-weight: 500;
        color: #fff;
        margin: 0;
    }   
    
    .buttons-wrapper{
        display: flex;
        align-items: center;

        .title-button-wrapper{
            padding: 10px 20px;
            border: none;
            color: #FFF;
    
            font-weight: 600;
    
            background: linear-gradient(135deg, #FE5833 0%, #FE7455 100%);
            border-radius: 6px;
    
            cursor: pointer;
        }
    }

    
    .close-icon-wrapper{
        color: $font-primary;
        font-size: 20px;

        cursor: pointer;
    }
}

@media only screen and (max-width: 767px) {
    .title-wrapper {
        min-width: 260px;
    }
}