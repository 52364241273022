@import "designSystem/colors.scss";

.notifications-wrapper {
    padding-right: 30px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;


    .action-wrapper {
        display: flex;
        align-items: center;

        .action-icon {
            width: 16px;
            height: 16px;

            cursor: pointer;
        }
    }

    .table-wrapper {
        .ant-table-wrapper {
            .ant-table {
                table {
                    min-width: 1000px;
                    tbody {
                        tr {
                            height: 80px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .notifications-wrapper {
        padding: 20px;
    }

    
    .modal-wrapper{

        .modal-components {
            width: 100%;

            padding: 0 30px;
        }
    }
}
