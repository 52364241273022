@import 'designSystem/colors.scss';

.info-wrapper {
    display: flex;
    align-items: center;

    .info-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;

        .image-wrapper {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $light-background;
        }

        .playIcon {
            padding-left: 3px;
            opacity: 0.5;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
