@import "designSystem/colors.scss";

.auth-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(169.24deg, #1e2630 -17.78%, #16181d 91.97%), #c4c4c4;

    .background-image {
        width: 100%;
        height: 100%;
        position: absolute;

        .background-image__overlay {
            width: 100%;
            height: 100%;
            background-color: white;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.8;
        }

        img {
            width: 100%;
            height: 100%;
            filter: blur(10px);
        }
    }

    .app-logo {
        width: 80px;
        margin-bottom: 30px;
        object-fit: contain;
    }

    .auth-layout__card {
        z-index: 5;
        background-color: #1e2630;
        border-radius: 27px;
        padding: 40px 40px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        width: 600px;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin: 0;
            margin-bottom: 20px;
            color: $font-primary;
        }

        .separator {
            display: block;
            margin-bottom: 10px;
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            color: $font-primary;
            font-weight: 500;
        }

        .error-message-global {
            color: $error;
            text-align: center;
            margin: 20px;
        }
    }
}
