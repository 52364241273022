@import "./src/designSystem/colors.scss";

.general-entrance-wrapper{
    padding-right: 30px;
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .general-entrance-wrapper{
        padding: 0 20px;
    }
}

td.ant-table-column-sort {
    background-color: transparent;
}


.header-wrapper{
    h3{
        margin: 0;
        font-weight: 500;
        color: $font-title;
    }
    h4{
        margin: 0;
        font-weight: 400;
        color: $primary;
    }
}