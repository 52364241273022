@import "designSystem/colors.scss";

.home-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .card-component {
        img {
            max-width: 150px;
            margin-bottom: 10px;
        }

        h1 {
            font-size: 25px;
            color: $primary;
        }
    }
}
