@import "designSystem/colors.scss";

.confirmation-modal-wrapper{
    width: 623px;
    // height: 309px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 30px;

    color: $font-primary;
    background-color: $background;

    text-align: center;

    h2{
        color: $font-primary;
    }   
    
    h3{
        color: $font-subtitle;
    }

    img{
        width: 90px;
        height: 95px;

        margin-bottom: 24px;
    }

    .icon-wrapper{
        width: 65px;
        height: 65px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 35px;

        background-color: $primary;
        color: $font-primary;

        margin: 15px auto;
    }

    .center-button-wrapper{
        width: 100%;
        font-size: 15px;

        margin-top: 16px;

        .confirm-button{
            width: 100%;
            background-color: $primary;
            color: $font-primary;

            border-radius: 100px;
            border: none;

            padding: 15px 20px;
        }
    }

    .button-wreapper{
        margin: auto 0;
        margin-left: auto;

        font-size: 15px;

        button{
            cursor: pointer;
        }
        
        .deny-button{
            background-color: $background;
            color: $font-primary;
            border: none;
            margin-right: 16px;
        }

        .confirm-button{
            width: 130px;
            background-color: $primary;
            color: $font-primary;

            border-radius: 100px;
            border: none;

            padding: 15px 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .confirmation-modal-wrapper{
        width: 330px;

        .button-wreapper{
            margin: auto;
        }
    }
}