@import "./src/designSystem/colors.scss";

.stage-wrapper{
    padding-right: 30px;
    height: 100%;

}

.checkin-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
        width: 30px;
        height: 30px;
    }

    .not-checked{
        display: flex;
        justify-content: center;
        align-items: center;
        
        color: #ff0000;
        font-size: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .stage-wrapper{
        padding: 0 20px;
    }
}

td.ant-table-column-sort {
    background-color: transparent;
}

.header-wrapper{
    h3{
        margin: 0;
        font-weight: 500;
        color: $font-title;
    }
    h4{
        margin: 0;
        font-weight: 400;
        color: $primary;
    }
}