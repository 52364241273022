@import "designSystem/colors.scss";

.no-info-wrapper{
    flex-grow: 1;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img{
        color: $accent;
        width: 70px;
        height: 70px ;
    }

    h3{
        padding-top: 24px !important;
        color: $accent !important;
        text-transform: none !important;
    }
}