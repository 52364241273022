@import "designSystem/colors.scss";

.image-picker-wrapper {
    padding: 0 30px;
    
    .image-component-wrapper{
        max-width: 500px;
        height: 120px;
        border-radius: 3px;
        background-color: $light-background;

        display: block;
        display: flex;
        justify-content: center;

        cursor: pointer;

        .component-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin: auto 0;
            .icon-wrapper{
                width: 60px;
                height: 60px;
                margin: 0 auto;
            }
    
            h2 {
                color: $font-subtitle;
                margin: 0;
            }
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            object-fit: cover;
        }

        
    }
    
    .image-wrapper {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: gray;
        position: relative;
        display: block;

        img {
            
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        .add {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $primary;
            position: absolute;
            bottom: 0;
            right: 10px;
            color: $font-primary;
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
        }
    }

    h6{
        display: flex;
        justify-content: center;
        color: $font-primary;
    }

    span{
        margin: 0 auto;
    }

}


@media only screen and (max-width: 767px) {
    .image-picker-wrapper {
        padding: 0;
    }
}