@import 'designSystem/colors.scss';

.table-wrapper {
    width: 100%;
    padding-top: 16px;

    .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
        background: unset;
    }

    .table_row {
        background-color: $background;
        color: $font-primary;
        td {
            border-bottom: none;
        }
    }

    .ant-table-wrapper {
        background-color: $background;

        .ant-table {
            background-color: $background;
            overflow-x: auto;

            .ant-table-body {
                flex: auto;

                .highIndex {
                    z-index: 99999;
                }
            }

            table {
                overflow-x: auto;
                background-color: $background;

                thead {
                    tr {
                        overflow: hidden;
                        background: none;

                        th {
                            padding: 1rem 24px;
                            line-height: 13px;
                            border-bottom: none;
                            color: $font-subtitle;
                            background-color: $light-background;

                            .ant-table-column-sorters {
                                padding: 0;
                            }

                            &:first-child {
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                            }
                            &:last-child {
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }

                            &:not(.ant-table-column-sort) {
                                &:hover {
                                    .ant-table-column-sorters {
                                        .ant-table-column-sorter {
                                            .ant-table-column-sorter-inner {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }

                                .ant-table-column-sorters {
                                    .ant-table-column-sorter {
                                        .ant-table-column-sorter-inner {
                                            opacity: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        height: 80px;
                        td {
                            padding: 1rem 24px;
                        }
                    }
                }
            }

            .ant-table-cell {
                max-width: 600px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ant-table-container::before,
        .ant-table-container::after {
            box-shadow: none;
        }

        .ant-pagination {
            border-top: none;
            padding: 15px 24px;
            margin: 0;
            color: $font-primary;

            .ant-pagination-item,
            .ant-pagination-prev,
            .ant-pagination-next {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                box-sizing: border-box;
                border-radius: 5px;
                transition: 0.1s border-color;
                background-color: $background;

                &:hover {
                    // border-color: $primary-blue-light;
                    // transition: 0.1s border-color;
                }

                a {
                    color: $font-primary;
                }
                &.ant-pagination-item-active {
                    border: none;
                    background-color: $accent;
                    color: $font-primary;
                }
            }

            .ant-pagination-item-link {
                border-radius: 5px;
                background-color: $background;
                border-color: $font-subtitle;
                color: $font-primary;
            }

            .ant-pagination-options {
                .ant-select {
                    display: flex;
                    height: 30px;
                    border-radius: 5px;

                    .ant-select-selector {
                        height: 100%;
                        border-radius: 5px;
                    }
                }
            }

            .ant-select-dropdown {
                pointer-events: unset !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .table-wrapper {
        .ant-table-wrapper {
            .ant-table {
                table {
                    tbody {
                        tr {
                            height: 80px;
                            td {
                            }
                        }
                    }
                }
            }
        }
    }
}
