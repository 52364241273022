@import './src/designSystem/colors.scss';


.modal-products-wrapper{
    width: 100%;
    height: 100%;

    .inputs-wrapper {
        width: 40%;
        min-width: 650px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        .row {
            display: flex;
            align-items: center;
            width: 100%;

            div:first-child {
                margin-right: 20px;
            }
        }

        .input-wrapper {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}


@media only screen and (max-width: 767px) {
    .modal-wrapper{

        .modal-components {
            width: 100%;

            padding: 0 30px;
        }
    }
}
