@import './src/designSystem/colors.scss';

.secondary-wrapper{
    position: relative;

    .subtitle-button-wrapper{
        min-width: 100px;
        padding: 10px 14px;
        padding-right: 8px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        
        border: none;
        color: #FFF;

        font-weight: 600;

        background-color: $accent;
        border-radius: 6px;

        cursor: pointer;

        margin-right: 20px;

        .icon-option-wrapper{
            width: 30px;
            height: 16px;
            font-size: 26px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            border-left: 1px solid #A1A1A1;
            margin-left: 8px;
        }
    }

    .options-wrapper{
        margin-top: 5px;
        position: absolute;
        bottom: 1;
        left: 0;

        color: $font-primary;

        width: 100%;

        
        background-color: $light-background;
        
        border-radius: 6px;
        
        z-index: 999;
        
        .options-items-wrapper{
            height: 42px;
            padding: 10px;
            
            display: flex;
            align-items: center;
            
            border-bottom: 1px solid #FFFFFF16;

            svg{
                margin-right: 10px;
            }

            cursor: pointer;

            &:hover{
                background-color: #2b333b;
            }
        }
        
        label:first-child{
            border-radius: 6px 6px 0 0;
        }
        
        label:last-child{
            border-radius: 0 0 6px 6px;
            border-bottom: none;
        }
    }
}
