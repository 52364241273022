@import "designSystem/colors.scss";

.events-wrapper{
    flex-grow: 1;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding-right: 30px;
    
    .action-buttons-wrapper {
        display: flex;
        align-items: center;
        
        color: #fff;
        
        div:first-child {
            margin-right: 16px;
        }
        
        div {
            cursor: pointer;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .events-wrapper {
        padding: 0  30px;
    }
}
