@import "designSystem/colors.scss";


.TimePicker-wrapper{
    width: 100%;

    label {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 15px;
        color: $font-primary;
        font-size: 14px;
    }
    
    .ranger-wrapper{
        width: 100%;
        background-color: $light-background;
        border: none;
        border-radius: 5px;
        padding: 15px 10px;
        height: auto !important;
        box-shadow: none;
        
        input {
            color: $font-primary;
            &::placeholder{
                color: $font-subtitle;
            }
        }
        svg {color: $font-primary}
        span {background-color: $light-background;}
        .ant-picker{
            padding: 0;

        }

        .ant-picker-active-bar{
            display: none;
        }

    }
}