@import "designSystem/colors.scss";

.general-entrance-detail-wrapper{
    padding-right: 30px;
    height: 100%;

    .request-fail-wrapper{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        height: 100%;
    }

    .total-payment-wrapper{
        padding-top: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        color: $accent;
        display: block;
        text-align: end;

    }

    .entrance-detail-state-wrapper{
        .detail-state-payd-wrapper{
            width: 80px;
            padding: 6px 10px;
            border-radius: 6px;
            background-color: #6FCF9718;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            color: #A5FFCD;
    
            cursor: default;
        }
    }
}



@media only screen and (max-width: 767px) {
    .general-entrance-detail-wrapper{
        padding: 0 30px;
    }    
}
