@import "designSystem/colors.scss";

.content-modalEvents-wrapper {
    width: 100%;
    height: 100%;

    padding: 0 30px;

    .inputs-wrapper {
        width: 40%;
        min-width: 650px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-top: 20px;

        .row {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .row-first {
                margin-right: 20px;
            }
        }

        .teste-qualquer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        
        .input-wrapper {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .content-modalEvents-wrapper {
        .inputs-wrapper {
            width: 100%;
            min-width: 100%;
            box-sizing: border-box;

            .row {
                flex-wrap: wrap;

                .row-first {
                    margin-right: 0px;
                }
            }
        }
    }
}