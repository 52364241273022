@import "designSystem/colors.scss";

.paid-out-wrapper{
    width: 80px;
    padding: 6px 10px;
    border-radius: 6px;
    background-color: #6FCF9718;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #A5FFCD;

    cursor: default;
}

@media only screen and (max-width: 767px) {
    .paid-out-wrapper{
    }    
}
