@import "designSystem/colors.scss";

.react-modal {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    height: 100%;

    background-color: $background;

    .modal-header,
    .modal-footer{
        padding: 15px 30px;
    }

    .modal-body {
        flex: 1;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 767px) {
    .react-modal {
        .modal-body {
            margin-bottom: 30px;
        }
    }
}
